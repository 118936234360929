import styled from 'styled-components';
// import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 90vh;
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
`;

export const MuteButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${({ isMuted }) => (isMuted ? '#e31837' : 'black')};
  color: white;
  font-size: 16px;
  cursor: pointer;
  z-index: 3;

  &:hover {
    background-color: ${({ isMuted }) => (isMuted ? '#b8162b' : '#333')};
  }
`;

export const MainContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  text-align: center;
`;

export const MainItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
`;

export const MainH1 = styled.h1`
  font-size: clamp(2rem, 6vw, 4rem);
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90vw; /* Prevents wrapping */
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 900;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
    letter-spacing: 1.5px;
    max-width: 85vw;
  }
`;

export const MainH1Underline = styled.div`
  width: 0%;
  height: 3px;
  background-color: #e31837;
  margin: 0 auto;
  transition: width 0.8s ease-out;
`;

export const MainP = styled.p`
  font-size: clamp(1.5rem, 2.5vw, 3rem);
  margin-bottom: 2rem;
`;

export const MainBtnLink = styled.a`
  font-size: clamp(1.2rem, 4vw, 1.75rem);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  background: #e31837;
  color: #fff;
  text-decoration: none;

  &:hover {
    background: black;
    color: white;
  }
`;
