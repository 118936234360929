import React, { useState } from 'react';
import {
    SlideBackground,
    MenuHead,
    MenuButtonContainer,
    MenuButton,
    MenuImageContainer,
    MenuImage
} from './SliderData';

import Menu1 from '../../images/MENU1.JPG';
import Menu2 from '../../images/MENU2.JPG';
import { FaFileDownload, FaUtensils } from 'react-icons/fa';

const MenuSection = () => {
    const [fullImage, setFullImage] = useState(null);

    // Open full image
    const openImage = (image) => {
        setFullImage(image);
    };

    // Close full image
    const closeImage = () => {
        setFullImage(null);
    };

    return (
        <SlideBackground>
            {/* Section Title */}
            <MenuHead>SPARTAN'S GRILL MENU</MenuHead>

            {/* Buttons */}
            <MenuButtonContainer>
    {/* Corrected PDF download link */}
    <MenuButton as="a" href="/menu.pdf" download>
        DOWNLOAD MENU
        <FaFileDownload />
    </MenuButton>

    {/* Fixed the 'to' prop issue by ensuring it correctly uses Link */}
    <MenuButton href="https://mylightspeed.app/RMZFEVKW/C-ordering/menu" rel="noreferrer" target="_blank" aria-label="Redirect to Coming Soon">
        <FaUtensils />
    </MenuButton>
</MenuButtonContainer>
            {/* Menu Images */}
            <MenuImageContainer>
                <MenuImage src={Menu1} alt="Spartan's Grill Menu Page 1" onClick={() => openImage(Menu1)} />
                <MenuImage src={Menu2} alt="Spartan's Grill Menu Page 2" onClick={() => openImage(Menu2)} />
            </MenuImageContainer>

            {/* Fullscreen Image Modal */}
            {fullImage && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        background: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                    onClick={closeImage}
                >
                    <img
                        src={fullImage}
                        alt="Full View"
                        style={{
                            width: '90%',
                            maxWidth: '1000px',
                            borderRadius: '10px',
                            boxShadow: '0px 10px 30px rgba(255, 255, 255, 0.2)',
                        }}
                    />
                </div>
            )}
        </SlideBackground>
    );
};

export default React.memo(MenuSection);
