import React, { useEffect, useRef, useState, Suspense } from 'react';
import gsap from 'gsap';
import { MainContainer, VideoContainer, MainContent, MainItems, MainH1, MainH1Underline, MainP, MainBtnLink, MuteButton } from './MainElements';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Photos } from '../Collage/Photos';
import { SocialMediaWrap, SocialItems, SocialH1, SocialIcons, SocialIconLink } from '../Information/InformationElement';

const About = React.lazy(() => import('../About'));
const Collage = React.lazy(() => import('../Collage'));
const Allergy = React.lazy(() => import('../Allergy'));

const Main = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const titleRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => console.error('Error playing video:', error));
      }
    };
    playVideo();
  }, []);

  useEffect(() => {
    if (titleRef.current) {
      const text = titleRef.current.innerText.split("").map(letter => {
        return letter === " " ? "&nbsp;" : letter; // Preserve spaces
      });
      titleRef.current.innerHTML = ""; 

      text.forEach(letter => {
        const span = document.createElement("span");
        span.innerHTML = letter; // Use innerHTML to respect &nbsp;
        span.style.display = "inline-block";
        titleRef.current.appendChild(span);
      });

      const tl = gsap.timeline();

      tl.fromTo(
        titleRef.current.children,
        { opacity: 0, y: 50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1.2,
          ease: "power3.out",
          stagger: 0.08,
          onComplete: () => {
            gsap.to(underlineRef.current, {
              width: "100%",
              duration: 0.8,
              ease: "power3.out"
            });
          }
        }
      );
    }
  }, []);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <>
      <MainContainer>
        <VideoContainer>
          <video ref={videoRef} src="/SpartansGrill.webm" autoPlay loop playsInline preload="auto" muted={isMuted}></video>
          <MuteButton isMuted={isMuted} onClick={toggleMute}>
            {isMuted ? '🔇 Unmute' : '🔊 Mute'}
          </MuteButton>
        </VideoContainer>

        <MainContent>
          <MainItems>
            <MainH1 ref={titleRef}>SPARTAN'S GRILL</MainH1>
            <MainH1Underline ref={underlineRef} />
            <MainP>Modern and Traditional Greek Fast Food Restaurant</MainP>
            <MainBtnLink href="https://mylightspeed.app/RMZFEVKW/C-ordering/menu" rel="noreferrer" target="_blank" aria-label="Redirect to Coming Soon">
              Order Now
            </MainBtnLink>
          </MainItems>
        </MainContent>
      </MainContainer>

      <Suspense fallback={<div>Loading About...</div>}>
        <About />
      </Suspense>
      <SocialMediaWrap>
        <SocialItems>
          <SocialH1>Follow Us On</SocialH1>
          <SocialIcons>
            <SocialIconLink href="https://www.facebook.com/SpartansGrill123/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </SocialIconLink>
            <SocialIconLink href="https://www.instagram.com/spartansgrill/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </SocialIconLink>
          </SocialIcons>
        </SocialItems>
      </SocialMediaWrap>

      <Suspense fallback={<div>Loading Collage...</div>}>
        <Collage photos={Photos} />
      </Suspense>

      <Suspense fallback={<div>Loading Allergy Information...</div>}>
        <Allergy />
      </Suspense>
    </>
  );
};

export default Main;
