import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaPhone } from 'react-icons/fa';

/* Navbar Container */
export const Nav = styled.nav`
  height: 120px; 
  display: flex;
  justify-content: center; /* Ensures content stays centered */
  align-items: center;
  padding: 0 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;

  @media screen and (max-width: 768px) {
    height: 120px;
    padding: 0 15px;
  }
`;

/* Navbar Wrapper */
export const NavbarWrapper = styled.div`
  display: flex;
  justify-content: center; /* Center aligns everything */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Allows it to center */
  text-decoration: none;
`;

/* Logo Container */
export const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* Logo Image */
export const LogoImg = styled.img`
  width: 80px; /* Adjusted */
  height: auto;
  max-height: 90%;
  object-fit: contain;

  @media screen and (max-width: 768px) {
    width: 75px;
  }
`;

/* Icons Container */
export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  right: 0; /* Keeps icons on the right */
`;

/* Menu Icon */
export const NavIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
`;

export const Bars = styled(FaBars)`
  font-size: 2rem;
`;

/* Phone Icon */
export const PhoneIconLink = styled.a`
  color: white;
  text-decoration: none;
  transition: 0.25s ease-out;

  &:hover {
    color: #e31837;
  }
`;

export const Phone = styled(FaPhone)`
  width: 28px;
  height: 28px;

  @media screen and (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
`;
