import React from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import {AllergyContainer, AllergyWrap, AllergyItems, AllergyH1, AllergyP, AllergyBtnLink, AllergyP1} from './AllergyPage'

const Allergy = () => {
    return (
        <Fragment>
            <AllergyContainer>
                <AllergyWrap>
                    <AllergyItems>
                        <AllergyH1>FOOD ALLERGY NOTICE:</AllergyH1>
                        <AllergyP>Food Prepared In This Establishment May Contain Wheat, Fish, Dairy, Eggs, Soy And Nuts</AllergyP>
                        <AllergyP1>Product Images Are For Illustrative Purposes Only And May Differ From The Actual Product</AllergyP1>
                        <AllergyBtnLink href="https://mylightspeed.app/RMZFEVKW/C-ordering/menu" rel="noreferrer" target="_blank" aria-label="Redirect to Coming Soon">
                        
                                    Order Now
                            
                        </AllergyBtnLink>
                        
                    </AllergyItems>
                </AllergyWrap>
            </AllergyContainer> 
        </Fragment>
    )
}

export default Allergy
