import styled from 'styled-components';
// import woodBackground from '../../images/black-wall-1.jpeg';
// import { Link } from 'react-router-dom';

// Background Container
export const SlideBackground = styled.div`
    position: relative;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7));;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    min-height: 100vh;

    @media screen and (max-width: 768px) {
        padding: 2rem 1rem;
    }
`;

// Menu Section Title
export const MenuHead = styled.div`
    text-align: center;
    color: #fff;
    font-size: clamp(2rem, 5vw, 3.5rem);
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: 1px;
`;

// Buttons Container
export const MenuButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

// Button Styling
export const MenuButton = styled.a`
    background: #e31837;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background 0.3s ease-in-out;

    &:hover {
        background: black;
    }

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
`;

// Menu Image Container
export const MenuImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 900px;
    margin-top: 1rem;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 0 1rem;
    }
`;

// Individual Menu Image with Hover & Click to Enlarge
export const MenuImage = styled.img`
    width: 100%;
    max-width: 900px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        transform: scale(1.05); /* Slight zoom effect */
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
    }
`;
