import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarMenu, SidebarLink, SideBtnWrap,SidebarRouteLink } from "./SidebarElements";

function Sidebar({isOpen, toggle}){
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarMenu>
                <SidebarLink to='/'>Home</SidebarLink>
                <SidebarLink to='/about'>About Us</SidebarLink>
                <SidebarLink to='/menu'>Menu</SidebarLink>
            </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRouteLink href="https://mylightspeed.app/RMZFEVKW/C-ordering/menu" rel="noreferrer" target="_blank" aria-label="Redirect to Coming Soon">
                        Order Now
                    </SidebarRouteLink>
                </SideBtnWrap>
        </SidebarContainer>
    )
}

export default React.memo(Sidebar)
