import React, { useEffect } from 'react';
import gsap from 'gsap';
import { 
    Nav, 
    NavbarWrapper, 
    NavLink, 
    LogoImg, 
    NavIcon, 
    Bars, 
    IconsContainer, 
    PhoneIconLink, 
    Phone 
} from './NavbarElements';
import Logo from '../../images/logo.png';

const Navbar = ({ toggle }) => {
    
    useEffect(() => {
        const tl = gsap.timeline({ delay: 0.5 });

        // Ensures elements are hidden before animation starts
        gsap.set(".logo-img", { opacity: 0, y: -30 });
        gsap.set(".nav-icon", { opacity: 0, y: -30 });
        gsap.set(".icons-container", { opacity: 0, scale: 0.8 });

        tl.to(".logo-img", { opacity: 1, y: 0, duration: 1.2, ease: "power3.out" })
          .to(".nav-icon", { opacity: 1, y: 0, duration: 1.2, ease: "power3.out" }, "-=0.8")
          .to(".icons-container", { opacity: 1, scale: 1, duration: 1, ease: "power3.out" }, "-=1");

    }, []);

    return (
        <Nav>
            <NavbarWrapper>
                <NavLink to='/'>
                    <LogoImg src={Logo} alt='Spartan Soldier Logo' className="logo-img" />
                </NavLink>
                <IconsContainer className="icons-container">
                    <NavIcon onClick={toggle} className="nav-icon">
                        <Bars />
                    </NavIcon>
                    <PhoneIconLink href="tel:1-516-206-3000">
                        <Phone />
                    </PhoneIconLink>
                </IconsContainer>
            </NavbarWrapper>
        </Nav>
    );
};

export default React.memo(Navbar);
